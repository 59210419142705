import React, { useState } from 'react';
import GlobalStore, { LOGOUT } from '../../store/global-store';
import Pagination from '../common/pagination';
import { addDays, format } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import { useInit } from '../common/custom-hooks';
import API from '../../lib/api';
import AdminDetail from './detail';

registerLocale('ko', ko);

const AdminList = () => {
  const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [list, setList] = useState<IV1ApplyItem[]>([]);
  const [searchType, setSearchType] = useState<'name' | 'phone'>('name');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectIdxs, setSelectIdxs] = useState<string[]>([]);
  const [detailInfo, setDetailInfo] = useState<IV1ApplyItem | null>(null);

  const store = GlobalStore.useContainer();

  const fetch = async (
    startDate: Date,
    endDate: Date,
    page: number,
    searchType: 'name' | 'phone',
    searchKeyword: string
  ) => {
    const res = await API.common.applyList({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      page,
      searchType,
      searchKeyword,
    });

    if (res.code === 200 && res.result) {
      setList(res.result.list);
      setTotalPage(Math.ceil(res.result.total / 5));
    }
  };

  useInit(() => {
    fetch(startDate, endDate, page, searchType, searchKeyword);
  });

  const handleRemoveClicked = async () => {
    if (selectIdxs.length < 1) {
      return window.alert('선택된 항목이 없습니다.');
    }

    const res = await API.common.applyRemove(selectIdxs.join(','));
    if (res.code === 200) {
      window.alert('삭제 되었습니다.');
      fetch(startDate, endDate, 1, searchType, searchKeyword);
    } else {
      window.alert('삭제 실패');
    }
  };

  const handleExcelDownload = () => {
    window.location.href = API.common.download({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      searchType,
      searchKeyword,
    });
  };

  return (
    <div className="list-container">
      <div className="list-wrap">
        <div className="logo pointer" onClick={() => window.location.reload()} />
        <div className="table-wrap">
          <div className="btn-area">
            <button onClick={handleExcelDownload}>내려받기</button>
            <button onClick={handleRemoveClicked}>삭제</button>
            <button className="logout" onClick={() => store.dispatchUserAction({ type: LOGOUT })}>
              로그아웃
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" checked={selectIdxs.filter((idx) => list.map((item) => item.idx).includes(idx)).length === list.length} onChange={(e) => {
                    const otherList = selectIdxs.filter((idx) => !list.map((item) => item.idx).includes(idx));
                    if (e.target.checked) {
                      setSelectIdxs([...otherList, ...list.map((item) => item.idx)]);
                    } else {
                      setSelectIdxs(otherList);
                    }
                  }} />
                </th>
                <th>날짜</th>
                <th>이름</th>
                <th>생년월일</th>
                <th>연락처</th>
                <th>병원 예약 여부</th>
                <th>보기</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: IV1ApplyItem, i: number) => {
                const data = JSON.parse(item.data);
                return (
                  <tr key={i}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectIdxs.includes(item.idx)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectIdxs([...selectIdxs, item.idx]);
                          } else {
                            setSelectIdxs(selectIdxs.filter((idx) => idx !== item.idx));
                          }
                        }}
                      />
                    </td>
                    <td>{format(new Date(item.reg_date), 'yyyy-MM-dd')}</td>
                    <td>{item.name}</td>
                    <td>{data.birth}</td>
                    <td>{item.phone}</td>
                    <td>{data.question1}</td>
                    <td>
                      <button onClick={() => setDetailInfo(item)}>전체보기</button>
                    </td>
                  </tr>
                );
              })}
              {list.length < 1 && (
                <tr>
                  <td colSpan={7}>검색된 데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={page}
            totalPageCount={totalPage}
            onPageClicked={(page) => {
              setPage(page);
              fetch(startDate, endDate, page, searchType, searchKeyword);
            }}
          />
        </div>
        <div className="search-wrap">
          <div className="search-row">
            <span className="title">기간검색</span>
            <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} locale="ko" />
            <span className="bridge">~</span>
            <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} locale="ko" />
          </div>
          <div className="search-row">
            <span className="title">
              <select onChange={(e) => setSearchType(e.target.value as 'name' | 'phone')} value={searchType}>
                <option value="name">신청자명</option>
                <option value="phone">연락처</option>
              </select>
            </span>
            <input type="text" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            <button
              className="btn"
              onClick={() => {
                setPage(1);
                fetch(startDate, endDate, 1, searchType, searchKeyword);
              }}
            >
              검색
            </button>
          </div>
        </div>
      </div>
      {detailInfo && <AdminDetail detailInfo={detailInfo} onClose={() => setDetailInfo(null)} />}
    </div>
  );
};

export default AdminList;
