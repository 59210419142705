import React from 'react';
import GlobalStore from '../../../../store/global-store';

const StepButtons = ({ onNext }: { onNext?: (callback: () => void) => void }) => {
  const store = GlobalStore.useContainer();
  return (
    <div className="btn-area two-btns">
      <button className="btn" onClick={() => store.setStep(store.step - 1)}>
        뒤로
      </button>
      <button
        className="btn"
        onClick={() => {
          if (onNext) {
            onNext(() => store.setStep(store.step + 1));
          } else {
            store.setStep(store.step + 1);
          }
        }}
      >
        다음
      </button>
    </div>
  );
};

export default StepButtons;
