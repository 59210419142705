import React from 'react';
import GlobalStore from '../../../store/global-store';

const ApplyV1Step1 = () => {
  const store = GlobalStore.useContainer();
  return (
    <div className="wrap step1">
      <div className="logo" />
      <div className="topdown-border-box">
        <div className="mb10">
          다이렉트시력교정 할인 시스템은 진료 시 필요한 기본 문진 등의 업무를 환자가 병원에 방문하기 전 미리 작성하여
          <br />
          인력, 소요 시간을 단축시키고 <span className="strong nounder">그 만큼의 비용을 수술비 할인(비급여)으로 돌려 드리는 시스템</span> 입니다.
        </div>
        <div>잘못된 정보를 기입 시 병원 진료 서비스 이용에 불이익이 있을 수 있으니 정확한 기입 부탁 드립니다.</div>
      </div>
      <div className="topdown-border-box center mt25 main-img">
        <img src="/img/apply_v1_step1_info.png" className="pc" alt="" />
        <img src="/img/apply_v1_step1_info_mobile.png" className="mobile" alt="" />
      </div>
      <div className="step1_box">
        ※ 위 내용을 숙지하였으며, 잘못된 정보 기입으로 인해 발생한 피해는 본인에게 있음을 알려드립니다.
      </div>
      <div className="btn-area">
        <button className="btn" onClick={() => store.setStep(2)}>
          네, 이해 했습니다.
        </button>
      </div>
    </div>
  );
};

export default ApplyV1Step1;
