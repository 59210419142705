import React from 'react';
import { useInit } from '../../../common/custom-hooks';

const SelectRange = ({
  value,
  prefix,
  items,
  onUpdate,
}: {
  value: string;
  prefix: string;
  items: IV1SelectRangeItems[];
  onUpdate(value: string): void;
}) => {
  useInit(() => {
    const values: string[] = value.split('/');
    onUpdate(
      items
        .map((item, i) => {
          return values[i] || '';
        })
        .join('/')
    );
  });

  const handleUpdate = (i: number, j: number) => {
    let values = value.split('/');
    values[i] = j.toString();
    onUpdate(values.join('/'));
  };

  const isChecked = (i: number, j: number) => {
    const values = value.split('/');
    return values[i] ? values[i] === j.toString() : false;
  };

  return (
    <div className="select-range-wrap">
      {items.map((item, i) => (
        <div className="select-range-item" key={i}>
          <div className={`left-name ${item.isSingleLeft ? 'single' : ''}`}>{item.leftText}</div>
          <div className="select-range">
            <div className="select-range-bar" />
            <ul>
              {[...Array(5)].map((value, j) => (
                <li key={j}>
                  <input
                    type="radio"
                    name={prefix + '_' + i}
                    id={prefix + '_' + i + '_' + j}
                    checked={isChecked(i, j)}
                    onChange={() => handleUpdate(i, j)}
                  />
                  <label htmlFor={prefix + '_' + i + '_' + j}>
                    <span />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className={`right-name ${item.isSingleRight ? 'single' : ''}`}>{item.rightText}</div>
        </div>
      ))}
    </div>
  );
};

export default SelectRange;
