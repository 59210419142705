import React, { useState } from 'react';
import GlobalStore from '../../../store/global-store';
import API from "../../../lib/api";

const ApplyV1Step7 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const store = GlobalStore.useContainer();

  const handleApply = async () => {
    if (!isAgree) {
      return window.alert('개인정보 수집에 동의해주셔야 신청이 가능합니다.');
    }

    if (!store.applyData) {
      return window.alert('올바르지 않은 접근입니다.');
    }

    const res = await API.common.apply(store.applyData);
    if (res.code === 200) {
      window.alert('감사합니다. 전송 되었습니다.');
      window.location.href = '/';
    } else {
      window.alert('죄송합니다. 전송을 실패 하였습니다.\n잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <div className="wrap step7">
      <div className="logo" />
      <div className="finish-title">문진이 끝났습니다. 개인정보 수집 동의 후 신청을 완료해 주세요.</div>
      <div className="topdown-border-box finish">
        <div className={`privacy-wrap ${isOpen ? 'on' : ''}`}>
          <div className="privacy">
            <input type="checkbox" id="privacy" checked={isAgree} onChange={(e) => setIsAgree(e.target.checked)} />
            <label htmlFor="privacy">
              <span />
              개인정보 수집을 동의합니다.<strong className="point">[필수]</strong>
            </label>
            <button onClick={() => setIsOpen(!isOpen)}>{isOpen ? '▲' : '▼'}</button>
          </div>
        </div>
        <div className={`privacy-content ${isOpen ? 'on' : ''}`}>
          개인정보 기본정보 : 이름/생년월일/핸드폰번호/주소/설문작성내용
          <br />
          개인정보 보유, 이용기간 : 3개월 후 자동파기
          <br />
          개인정보 수집∙이용주체 : 지에스안과의원
          <br />
          동의를 거부할 권리가 있으나, 거부시 이벤트 참여가 제한됩니다.
        </div>
      </div>
      <div className="btn-area two-btns">
        <button className="btn" onClick={() => store.setStep(6)}>
          뒤로
        </button>
        <button className="btn" onClick={handleApply}>
          전송하기
        </button>
      </div>
    </div>
  );
};

export default ApplyV1Step7;
