import React from 'react';
import GlobalStore from '../../../store/global-store';
import ApplyV1Step1 from './step1';
import '../../../css/v1.css';
import ApplyV1Step2 from './step2';
import ApplyV1Step3 from './step3';
import ApplyV1Step4 from './step4';
import ApplyV1Step5 from './step5';
import ApplyV1Step6 from './step6';
import ApplyV1Step7 from './step7';

const ApplyV1 = () => {
  const store = GlobalStore.useContainer();
  const renderSwitch = () => {
    switch (store.step) {
      case 1: {
        return <ApplyV1Step1 />;
      }
      case 2: {
        return <ApplyV1Step2 />;
      }
      case 3: {
        return <ApplyV1Step3 />;
      }
      case 4: {
        return <ApplyV1Step4 />;
      }
      case 5: {
        return <ApplyV1Step5 />;
      }
      case 6: {
        return <ApplyV1Step6 />;
      }
      case 7: {
        return <ApplyV1Step7 />;
      }
    }
  };

  return (
    <div className="apply">
      {renderSwitch()}
    </div>
  );
};

export default ApplyV1;
