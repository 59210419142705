import React from 'react';
import GlobalStore from '../../../store/global-store';
import StepButtons from './common/step-buttons';
import SelectItems, { SELECT_TYPE } from './common/select-items';
import SelectRange from './common/select-range';
import { useUpdate } from '../../common/custom-hooks';

const ApplyV1Step5 = () => {
  const store = GlobalStore.useContainer();

  const handleQuestion8Change = (value: string) => {
    const values = value.split(',');
    if (values.length > 0 && values[0] !== '없음' && values.filter((value: string) => value === '없음').length > 0) {
      store.updateApplyData('question8', '없음');
    } else {
      store.updateApplyData(
        'question8',
        values.length > 1 ? values.filter((value) => value !== '없음').join(',') : value
      );
    }
  };

  useUpdate(() => {
    if (!store.applyData) {
      return;
    }

    const value = store.applyData?.question8 || '';
    const values = value.split(',');

    if (store.applyData.question8Etc && !store.applyData.question8) {
      handleQuestion8Change(value ? [...values, '기타'].join(',') : '기타');
    }
  }, store.applyData);

  return (
    <div className="wrap step5">
      <div className="logo" />
      <div className="topdown-border-box">
        <div className="question-box first">
          <div className="question-box-wrap">
            <h3>7. 시력교정을 목적으로 타 안과에 방문한 적이 있습니까?</h3>
            <ul className="question-list three">
              <SelectItems
                prefix="question7"
                items={[{ text: '있다.' }, { text: '없다.' }, { text: '기억이 나지 않는다.' }]}
                type={SELECT_TYPE.RADIO}
                value={store.applyData?.question7 || ''}
                onChange={(value) => store.updateApplyData('question7', value)}
              />
            </ul>
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>
              8. 다음 중 특별히 선호하는 수술이 있습니까? <span className="point">(중복체크 가능)</span>
            </h3>
            <ul className="question-list three">
              <SelectItems
                prefix="question8"
                items={[
                  { text: '라식' },
                  { text: '라섹' },
                  { text: '스마일라식' },
                  { text: '렌즈삽입술' },
                  { text: '노안·백내장' },
                  { text: '없음' },
                ]}
                type={SELECT_TYPE.CHECKBOX}
                value={store.applyData?.question8 || ''}
                onChange={handleQuestion8Change}
              />
            </ul>
            <ul className="question-list etc">
              <li>
                <input
                  type="checkbox"
                  id="schedule6"
                  name="schedule"
                  checked={(store.applyData?.question8 || '').split(',').includes('기타')}
                  onChange={(e) => {
                    const value = store.applyData?.question8 || '';
                    const values = value.split(',');
                    if (e.target.checked) {
                      store.updateApplyData('question8', value ? [...values, '기타'].join(',') : '기타');
                    } else {
                      store.updateApplyData('question8', values.filter((value) => value !== '기타').join(','));
                    }
                  }}
                />
                <label htmlFor="schedule6">
                  <span />
                  기타
                </label>
                <input
                  type="text"
                  value={store.applyData?.question8Etc || ''}
                  onChange={(e) => {
                    store.updateApplyData('question8Etc', e.target.value);
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>9. 다음 중 본인에게 더 중요하다고 생각되는 쪽으로 체크해 주세요.</h3>
            <SelectRange
              prefix="question9"
              value={store.applyData?.question9 || ''}
              items={[
                { leftText: '경제적인\n수술 비용', rightText: '최신 장비\n및 첨단기술' },
                { leftText: '친절한\n의료 서비스', rightText: '병원의\n위치' },
                { leftText: '의료진의\n임상 경험', rightText: '병원 인지도', isSingleRight: true },
              ]}
              onUpdate={(value: string) => store.updateApplyData('question9', value)}
            />
          </div>
        </div>
      </div>
      <StepButtons
        onNext={(callback) => {
          if (!store.applyData?.question7 || !store.applyData?.question8 || !store.applyData?.question9) {
            return window.alert('선택/입력 안한 문항이 있습니다.');
          }

          if (store.applyData?.question8.includes('기타') && !store.applyData?.question8Etc) {
            return window.alert('기타 선택시 내용을 입력하셔야 합니다.');
          }

          callback && callback();
        }}
      />
    </div>
  );
};

export default ApplyV1Step5;
