import { fetch, METHOD } from './api-utils';

const CommonApiList = {
  sessionId: (): Promise<IRes<string>> => {
    return fetch<string>(METHOD.GET, '/get-session-id', {});
  },

  sendSmsAuthCode: (phone: number): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.GET, '/sms-auth-send', { phone });
  },

  validateSmsAuthCode: (code: string): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.GET, '/sms-auth-validate', { code });
  },

  apply: (reqData: IV1Apply): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/apply', { ...reqData });
  },

  applyList: (reqData: IV1ListReq): Promise<IRes<{ list: IV1ApplyItem[]; total: number }>> => {
    return fetch<{ list: IV1ApplyItem[]; total: number }>(METHOD.GET, '/apply-list', { ...reqData });
  },

  applyRemove: (idxs: string): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/apply-remove', { idxs });
  },

  download: (reqData: IV1DownloadReq): string => {
    return `https://api.gseyecenter.com/apply-excel-download?startDate=${reqData.startDate}&endDate=${reqData.endDate}&searchType=${reqData.searchType}&searchKeyword=${reqData.searchKeyword}`;
  },
};

export default CommonApiList;
