import React from 'react';
import StepButtons from './common/step-buttons';
import SelectItems, { SELECT_TYPE } from './common/select-items';
import GlobalStore from '../../../store/global-store';

const ApplyV1Step3 = () => {
  const store = GlobalStore.useContainer();
  return (
    <div className="wrap step3">
      <div className="logo" />
      <div className="topdown-border-box">
        <div className="question-box first">
          <div className="question-box-wrap">
            <h3>1. 해당 병원에 미리 예약 하셨습니까?</h3>
            <ul className="question-list three">
              <SelectItems
                prefix="isAlreadyReservation"
                items={[{ text: '예' }, { text: '아니오' }]}
                type={SELECT_TYPE.RADIO}
                value={store.applyData?.question1 || ''}
                onChange={(value) => store.updateApplyData('question1', value)}
              />
            </ul>
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>2. 검사를 예약한 날짜 혹은 희망하는 날짜를 적어주세요.</h3>
            <input
              type="text"
              placeholder="예) 00월 00일 오전"
              value={store.applyData?.question2 || ''}
              onChange={(e) => store.updateApplyData('question2', e.target.value)}
            />
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>3. 검사 후 수술을 진행한다면 일정은?</h3>
            <ul className="question-list two">
              <SelectItems
                prefix="schedule"
                items={[
                  { text: '검사일 당일' },
                  { text: '검사 후 3일 이내' },
                  { text: '검사 후 일주일 이내' },
                  { text: '검사 후 3개월 이내' },
                  { text: '검사 후 3개월 이후' },
                ]}
                type={SELECT_TYPE.RADIO}
                value={store.applyData?.question3 || ''}
                onChange={(value) => store.updateApplyData('question3', value)}
              />
            </ul>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={(callback) => {
          if (!store.applyData?.question1 || !store.applyData?.question2 || !store.applyData?.question3) {
            return window.alert('선택/입력 안한 문항이 있습니다.');
          }

          callback && callback();
        }}
      />
    </div>
  );
};

export default ApplyV1Step3;
