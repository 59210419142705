import React from 'react';
import { format } from 'date-fns';

const REFER = {
  facebook: 'SNS',
  cafe: '다이렉트시력교정 카페',
};

const AdminDetail = ({ detailInfo, onClose }: { detailInfo: IV1ApplyItem; onClose(): void }) => {
  const data = JSON.parse(detailInfo.data);
  const [question9_1, question9_2, question9_3] = data.question9.split('/');

  const renderQuestion9 = (answer: string) => {
    return (
      <ul>
        <li className={answer === '0' ? 'on' : ''}>0</li>
        <li className={answer === '1' ? 'on' : ''}>1</li>
        <li className={answer === '2' ? 'on' : ''}>2</li>
        <li className={answer === '3' ? 'on' : ''}>3</li>
        <li className={answer === '4' ? 'on' : ''}>4</li>
      </ul>
    );
  };

  return (
    <div className="detail-pop" onClick={() => onClose()}>
      <div
        className="detail-inner-pop"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="profile">
          <ul>
            <li>
              <span className="title">날짜</span>
              <span>{format(new Date(detailInfo.reg_date), 'yyyy/MM/dd')}</span>
            </li>
            <li>
              <span className="title">이름</span>
              <span>{detailInfo.name}</span>
            </li>
            <li>
              <span className="title">생년월일</span>
              <span>{data.birth}</span>
            </li>
            <li>
              <span className="title">연락처</span>
              <span>{detailInfo.phone}</span>
            </li>
            <li>
              <span className="title">병원 예약 여부</span>
              <span>{data.question1}</span>
            </li>
            <li>
              <span className="title">검사 희망 날짜</span>
              <span>{data.question2}</span>
            </li>
            <li>
              <span className="title">검사 후 수술 일정</span>
              <span>{data.question3}</span>
            </li>
            <li>
              <span className="title">렌즈착용</span>
              <span>
                {data.question6} {data.question6Text}
              </span>
            </li>
            <li>
              <span className="title">복용약</span>
              <span>{data.question5Text || '없음'}</span>
            </li>
            <li className="full">
              <span className="title">주소</span>
              <span>
                {data.address} {data.address2}
              </span>
            </li>
            <li className="full">
              <span className="title">가입경로</span>
              <span>{!data.refer ? '-' : REFER[data.refer as 'cafe' | 'facebook']}</span>
            </li>
          </ul>
        </div>
        <div className="question-answer-list">
          <ul>
            <li>
              <div className="question">1. 해당 병원에 미리 예약 하셨습니까?</div>
              <div className="answer">{data.question1}</div>
            </li>
            <li>
              <div className="question">2. 검사를 예약한 날짜 혹은 희망하는 날짜를 적어주세요.</div>
              <div className="answer">{data.question2}</div>
            </li>
            <li>
              <div className="question">3. 검사 후 수술을 진행한다면 일정은?</div>
              <div className="answer">{data.question3}</div>
            </li>
            <li>
              <div className="question">4. 다음 중 해당되는 사항이 있습니까? (중복체크 가능)</div>
              <div className="answer">
                {data.question4} {data.question4Etc}
              </div>
            </li>
            <li>
              <div className="question">5. 현재 복용중인 약이 있습니까?</div>
              <div className="answer">
                {data.question5} {data.question5Text}
              </div>
            </li>
            <li>
              <div className="question">6. 마지막으로 렌즈를 착용한 날짜와 종류는?</div>
              <div className="answer">
                {data.question6} {data.question6Text}
              </div>
            </li>
            <li>
              <div className="question">7. 시력교정을 목적으로 타 안과에 방문한 적이 있습니까?</div>
              <div className="answer">{data.question7}</div>
            </li>
            <li>
              <div className="question">8. 다음 중 특별히 선호하는 수술이 있습니까? (중복체크 가능)</div>
              <div className="answer">
                {data.question8} {data.question8Etc}
              </div>
            </li>
            <li>
              <div className="question">9. 다음 중 본인에게 더 중요하다고 생각되는 쪽으로 체크해 주세요.</div>
              <div className="answer">
                <div>경제적인 수술비용 {renderQuestion9(question9_1)} 최신 장비 및 첨단기술</div>
                <div>친절한 의료 서비스 {renderQuestion9(question9_2)} 병원의 위치</div>
                <div>의료진의 임상 경험 {renderQuestion9(question9_3)} 병원 인지도</div>
              </div>
            </li>
            <li>
              <div className="question">10. 다음 중 일상 생활에서 본인에게 해당하는 것이 있다면 체크해 주세요.</div>
              <div className="answer">{data.question10}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminDetail;
