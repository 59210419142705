import React, { useState, useEffect, useRef } from 'react';
import GlobalStore from '../../../store/global-store';
import StepButtons from './common/step-buttons';
import API from '../../../lib/api';
import { pad } from '../../../lib/api/utils';

const AUTH_LIMIT = 180;
const ApplyV1Step2 = () => {
  const [smsAuthResult, setSmsAuthResult] = useState('');
  const [isSendAuth, setIsSendAuth] = useState(false);
  const [authLimitSecond, setAuthLimitSecond] = useState<number | null>(null);
  const [authedPhone, setAuthedPhone] = useState('');

  const authCodeRef = useRef(null);
  const address2 = useRef(null);
  const isAuth = useRef(false);

  const store = GlobalStore.useContainer();

  useEffect(() => {
    const js = document.createElement('script');
    js.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    js.id = 'postcode';
    document.body.appendChild(js);

    return () => {
      document.body.removeChild(js);
    };
  }, []);

  const handleFindPostCode = () => {
    // @ts-ignore
    new daum.Postcode({
      oncomplete: (data: any) => {
        store.setApplyData({
          ...(store.applyData || {}),
          postcode: data.zonecode,
          address: data.roadAddress,
        });

        if (address2.current) {
          // @ts-ignore
          address2.current.focus();
        }
      },
    }).open({
      popupName: 'findPostCode',
    });
  };

  const handleSmsAuthSend = async () => {
    if (
      !store.applyData?.phone ||
      !/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g.test(store.applyData?.phone.toString())
    ) {
      return window.alert('올바른 번호를 입력하세요.');
    }

    await API.common.sendSmsAuthCode(store.applyData.phone);
    setIsSendAuth(true);
    setSmsAuthResult('');
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const timerPlay = (second: number) => {
      timer = setTimeout(() => {
        if (second - 1 === 0) {
          const authCodeInput: HTMLInputElement | null = authCodeRef.current;
          if (authCodeInput) {
            authCodeInput!.value = '';
          }

          setIsSendAuth(false);
          clearTimeout(timer);
          return;
        }

        if (isSendAuth) {
          setAuthLimitSecond(second - 1);
          timerPlay(second - 1);
        } else {
          clearTimeout(timer);
        }
      }, 1000);
    };

    if (isSendAuth) {
      setAuthLimitSecond(AUTH_LIMIT);
      timerPlay(AUTH_LIMIT);
    } else {
      setAuthLimitSecond(null);
      clearTimeout(AUTH_LIMIT);
    }
  }, [isSendAuth]);

  const renderTimer = () => {
    return authLimitSecond ? Math.floor(authLimitSecond / 60) + ':' + pad(authLimitSecond % 60) : '';
  };

  const handleSmsAuthCheck = async () => {
    const authCodeInput: HTMLInputElement | null = authCodeRef.current;

    if (!authCodeInput!.value) {
      return window.alert('인증번호를 입력하세요.');
    }

    const res = await API.common.validateSmsAuthCode(authCodeInput!.value);
    if (res.code === 200) {
      isAuth.current = true;
      setIsSendAuth(false);
      store.applyData?.phone && setAuthedPhone(store.applyData?.phone.toString());
      setSmsAuthResult('확인 되었습니다.');
    } else {
      setSmsAuthResult('잘못된 인증 번호입니다.');
    }
  };

  const handleNext = (callback: () => void) => {
    if (
      !store.applyData?.sex ||
      !store.applyData?.name ||
      !store.applyData?.birth ||
      !store.applyData?.phone ||
      !store.applyData?.postcode ||
      !store.applyData?.address ||
      !store.applyData?.address2
    ) {
      return window.alert('입력하지 않은 값이 있습니다.');
    }

    if (!isAuth.current || (store.applyData?.phone && store.applyData?.phone.toString() !== authedPhone)) {
      return window.alert('휴대폰 인증 후 다음 단계로 넘어갈 수 있습니다.');
    }

    callback && callback();
  };

  return (
    <div className="wrap step2">
      <div className="logo" />
      <div className="form-input">
        <span className="form-title">성별</span>
        <div className="form-input-data sex">
          <input
            type="radio"
            id="sex0"
            name="sex"
            checked={(store.applyData?.sex || '') === 'W'}
            onChange={() => store.updateApplyData('sex', 'W')}
          />
          <label htmlFor="sex0">
            <span />
            여성
          </label>
          <input
            type="radio"
            id="sex1"
            name="sex"
            checked={(store.applyData?.sex || '') === 'M'}
            onChange={() => store.updateApplyData('sex', 'M')}
          />
          <label htmlFor="sex1">
            <span />
            남성
          </label>
        </div>
      </div>
      <div className="form-input">
        <span className="form-title">이름</span>
        <div className="form-input-data">
          <input
            type="text"
            placeholder="실명을 입력하세요"
            value={store.applyData?.name || ''}
            onChange={(e) => store.updateApplyData('name', e.target.value)}
          />
        </div>
      </div>
      <div className="form-input">
        <span className="form-title">생년월일</span>
        <div className="form-input-data birth">
          <div className="birth_before">
            <input
              type="text"
              placeholder="6자리 입력"
              maxLength={6}
              value={store.applyData?.birth || ''}
              onChange={(e) => store.updateApplyData('birth', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-input-wrap">
        <div className="form-input">
          <span className="form-title">연락처</span>
          <div className="form-input-data">
            <input
              type="text"
              placeholder="'-' 구분 없이 입력"
              value={store.applyData?.phone || ''}
              onChange={(e) => {
                store.updateApplyData('phone', e.target.value);
                isAuth.current = false;
              }}
              maxLength={11}
            />
          </div>
        </div>
        <button className="btn" onClick={handleSmsAuthSend}>
          인증번호 전송
        </button>
      </div>
      {(isSendAuth || isAuth.current) && (
        <div className="form-input-wrap">
          <div className="form-input">
            <span className="form-title">인증번호</span>
            <div className="form-input-data rela">
              <input type="text" placeholder="인증번호 입력" ref={authCodeRef} />
              {isSendAuth && <span>{renderTimer()}</span>}
            </div>
          </div>
          <button className="btn" onClick={handleSmsAuthCheck}>
            인증번호 확인
          </button>
        </div>
      )}
      {smsAuthResult && <div className="form-input-wrap-error">{smsAuthResult}</div>}
      <div className="form-input-wrap">
        <div className="form-input">
          <span className="form-title">우편번호</span>
          <div className="form-input-data">
            <input
              type="text"
              readOnly={true}
              className="pointer"
              value={store.applyData?.postcode || ''}
              onChange={(e) => store.updateApplyData('postcode', e.target.value)}
              onClick={handleFindPostCode}
            />
          </div>
        </div>
        <button className="btn" onClick={handleFindPostCode}>
          우편번호 찾기
        </button>
      </div>
      <div className="form-input">
        <span className="form-title">주소</span>
        <div className="form-input-data">
          <input
            type="text"
            value={store.applyData?.address || ''}
            onChange={(e) => store.updateApplyData('address', e.target.value)}
            onClick={handleFindPostCode}
            className="pointer"
            readOnly={true}
          />
        </div>
      </div>
      <div className="form-input">
        <div className="form-input-data full">
          <input
            type="text"
            placeholder="나머지 주소 입력"
            value={store.applyData?.address2 || ''}
            onChange={(e) => store.updateApplyData('address2', e.target.value)}
            onClick={() => {
              if (!store.applyData?.address) {
                handleFindPostCode();
              }
            }}
            ref={address2}
          />
        </div>
      </div>
      <div className="form-input form-refer">
        <p>다이렉트시력교정 문진표를 어떻게 알고 오셨나요?</p>
        <div className="form-input-data">
          <span>
            <input
              type="radio"
              id="refer0"
              name="refer"
              checked={(store.applyData?.refer || '') === 'facebook'}
              onChange={() => store.updateApplyData('refer', 'facebook')}
            />
            <label htmlFor="refer0">
              <span />
              SNS
            </label>
          </span>
          <span>
            <input
              type="radio"
              id="refer1"
              name="refer"
              checked={(store.applyData?.refer || '') === 'cafe'}
              onChange={() => store.updateApplyData('refer', 'cafe')}
            />
            <label htmlFor="refer1">
              <span />
              다이렉트시력교정 카페
            </label>
          </span>
        </div>
      </div>
      <StepButtons onNext={handleNext} />
    </div>
  );
};

export default ApplyV1Step2;
