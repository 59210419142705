import { useEffect, useRef } from 'react';

export const useInit = (callback: () => void) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (savedCallback.current) {
      savedCallback.current();
    }
  }, [savedCallback]);
};

export const useUpdate = (callback: () => void, updateInfo: any) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (savedCallback.current) {
      savedCallback.current();
    }
  }, [savedCallback, updateInfo]);
};
