import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import './css/common.css';
import Admin from './components/admin';
import Apply from './components/apply';
import GlobalStore, { LOGOUT } from './store/global-store';
import API from './lib/api';

const App = () => {
  const store = GlobalStore.useContainer();

  useEffect(() => {
    const getSessionId = async () => {
      const res = await API.common.sessionId();
      if (res.code === 200) {
        localStorage.setItem('sessionId', res.result as string);
      }
    };

    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      getSessionId();
    }
  }, []);

  useEffect(() => {
    const checkLoginSession = async () => {
      const res = await API.user.isLogin();
      if (!res.result) {
        store.dispatchUserAction({ type: LOGOUT });
      }
    };

    if (store.isLoggedIn()) {
      checkLoginSession();
    }
  }, [store]);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Apply} />
        <Route exact path="/admin" component={Admin} />
      </Switch>
    </div>
  );
};

export default App;
