import React from 'react';
import GlobalStore from '../../../store/global-store';
import StepButtons from './common/step-buttons';
import SelectItems, { SELECT_TYPE } from './common/select-items';
import { useUpdate } from '../../common/custom-hooks';

const ApplyV1Step4 = () => {
  const store = GlobalStore.useContainer();

  const handleQuestion4Change = (value: string) => {
    const values = value.split(',');
    if (values.length > 0 && values[0] !== '없음' && values.filter((value: string) => value === '없음').length > 0) {
      store.updateApplyData('question4', '없음');
    } else {
      store.updateApplyData(
        'question4',
        values.length > 1 ? values.filter((value) => value !== '없음').join(',') : value
      );
    }
  };

  useUpdate(() => {
    if (!store.applyData) {
      return;
    }

    const value = store.applyData?.question4 || '';
    const values = value.split(',');
    if (store.applyData.question4Etc && !store.applyData.question4) {
      handleQuestion4Change(value ? [...values, '기타'].join(',') : '기타');
    }

    if (store.applyData?.question5Text && !store.applyData?.question5) {
      store.updateApplyData('question5', 'Y');
    }
  }, store.applyData);

  return (
    <div className="wrap step4">
      <div className="logo" />
      <div className="topdown-border-box">
        <div className="question-box first">
          <div className="question-box-wrap">
            <h3>
              4. 다음 중 해당되는 사항이 있습니까? <span className="point">(중복체크 가능)</span>
            </h3>
            <ul className="question-list question4">
              <SelectItems
                prefix="question4"
                items={[
                  { text: '없음' },
                  { text: '혈압' },
                  { text: '당뇨' },
                  { text: '류마티스/루푸스/강직성척추염' },
                  { text: '자가면역질환' },
                  { text: '포도막염' },
                  { text: '아토피' },
                  { text: '알레르기결막염' },
                  { text: '건조증' },
                  { text: '갑상선기능이상' },
                  { text: '임신중/임신예정' },
                  { text: '심장질환' },
                  { text: '결핵' },
                  { text: '모유수유' },
                ]}
                type={SELECT_TYPE.CHECKBOX}
                value={store.applyData?.question4 || ''}
                onChange={handleQuestion4Change}
              />
            </ul>
            <ul className="question-list etc">
              <li>
                <input
                  type="checkbox"
                  id="question4_etc"
                  name="question4"
                  checked={(store.applyData?.question4 || '').split(',').includes('기타')}
                  onChange={(e) => {
                    const value = store.applyData?.question4 || '';
                    const values = value.split(',');
                    if (e.target.checked) {
                      handleQuestion4Change(value ? [...values, '기타'].join(',') : '기타');
                    } else {
                      handleQuestion4Change(values.filter((value) => value !== '기타').join(','));
                    }
                  }}
                />
                <label htmlFor="question4_etc">
                  <span />
                  기타
                </label>
                <input
                  type="text"
                  value={store.applyData?.question4Etc || ''}
                  onChange={(e) => {
                    store.updateApplyData('question4Etc', e.target.value);
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>5. 현재 복용중인 약이 있습니까?</h3>
            <ul className="question-list question5">
              <li>
                <input
                  type="radio"
                  id="question5_Y"
                  name="question5"
                  checked={(store.applyData?.question5 || '') === '있음'}
                  onChange={() => store.updateApplyData('question5', '있음')}
                />
                <label htmlFor="question5_Y">
                  <span />있음
                </label>
                <input
                  type="text"
                  placeholder="예) 혈압약, 아스피린"
                  value={store.applyData?.question5Text || ''}
                  onChange={(e) => store.updateApplyData('question5Text', e.target.value)}
                />
              </li>
              <li>
                <input
                  type="radio"
                  id="question5_N"
                  name="question5"
                  checked={(store.applyData?.question5 || '') === '없음'}
                  onChange={() => store.updateApplyData('question5', '없음')}
                />
                <label htmlFor="question5_N">
                  <span />
                  없음
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div className="question-box">
          <div className="question-box-wrap">
            <h3>6. 마지막으로 렌즈를 착용한 날짜와 종류는?</h3>
            <ul className="question-list four">
              <SelectItems
                prefix="question6"
                items={[{ text: '소프트렌즈' }, { text: '난시교정렌즈' }, { text: '하드렌즈' }, { text: '착용안함' }]}
                type={SELECT_TYPE.RADIO}
                value={store.applyData?.question6 || ''}
                onChange={(value) => store.updateApplyData('question6', value)}
              />
            </ul>
            <div className="label-box">
              <label>
                <span>마지막 착용일</span>
                <input
                  type="text"
                  placeholder="예) 7월 15일 소프트 / 7월 23일 하드"
                  value={store.applyData?.question6Text || ''}
                  onChange={(e) => store.updateApplyData('question6Text', e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={(callback) => {
          if (!store.applyData?.question4 || !store.applyData?.question5 || !store.applyData?.question6) {
            return window.alert('선택/입력 안한 문항이 있습니다.');
          }

          if (store.applyData?.question4.includes('기타') && !store.applyData?.question4Etc) {
            return window.alert('기타 선택시 내용을 입력하셔야 합니다.');
          }

          if (store.applyData?.question5 === '있음' && !store.applyData?.question5Text) {
            return window.alert('복용중인 약이 있으실 경우 간략히 기재 부탁드립니다.');
          }

          if (store.applyData?.question6 !== '착용안함' && !store.applyData?.question6Text) {
            return window.alert('마지막 착용일을 기재해주세요.');
          }

          callback && callback();
        }}
      />
    </div>
  );
};

export default ApplyV1Step4;
