import React, { useState, useEffect } from 'react';

interface IProps {
  currentPage?: number; // default 1
  totalPageCount: number;
  onPageClicked: (page: number) => void;
}

const PAGE_LIST_PER_ONECE = 5;

const getPageGrp = (page: number, totlaPageCount: number): number[] => {
  let pageGrpIndex = Math.floor(page / PAGE_LIST_PER_ONECE);
  if (page % PAGE_LIST_PER_ONECE === 0) {
    pageGrpIndex -= 1;
  }

  const ret: number[] = [];
  for (let i = 0; i < PAGE_LIST_PER_ONECE; i += 1) {
    const pageNo = pageGrpIndex * PAGE_LIST_PER_ONECE + i + 1;
    if (pageNo > totlaPageCount) {
      break;
    }
    ret.push(pageGrpIndex * PAGE_LIST_PER_ONECE + i + 1);
  }

  return ret;
};

const Pagination = (props: IProps) => {
  const [currentPage, setCurrentPage] = useState(props.currentPage ? props.currentPage : 1);

  useEffect(() => {
    if (props.currentPage) {
      setCurrentPage(props.currentPage);
    }
  }, [props.currentPage]);

  const handlePageClicked = (page: number) => {
    if (currentPage === page || page < 1 || page > props.totalPageCount) {
      return;
    }

    setCurrentPage(page);
    props.onPageClicked(page);
  };

  const handlePrevNextClicked = (moveBy: -1 | 1) => {
    let targetPageNo = currentPage + moveBy * PAGE_LIST_PER_ONECE;
    if (targetPageNo < 1) {
      targetPageNo = 1;
    } else if (targetPageNo > props.totalPageCount) {
      targetPageNo = props.totalPageCount;
    } else {
      const targetGrp = getPageGrp(targetPageNo, props.totalPageCount);
      targetPageNo = targetGrp[0];
    }

    if (targetPageNo === currentPage) {
      return;
    }

    setCurrentPage(targetPageNo);
    props.onPageClicked(targetPageNo);
  };

  return (
    <div className="pagination">
      <div className="pagination_wrap">
        {props.totalPageCount > PAGE_LIST_PER_ONECE ? (
          <>
            <button className="btn_first cursor-pointer" onClick={() => handlePageClicked(1)}>
              <span>&lt;&lt;</span>
            </button>
            <button className="btn_previous cursor-pointer" onClick={() => handlePrevNextClicked(-1)}>
              <span>&lt;</span>
            </button>
          </>
        ) : null}
        <div className="pages">
          {getPageGrp(currentPage, props.totalPageCount).map((i) => (
            <button
              className={`btn_num cursor-pointer ${currentPage === i ? 'selected' : ''}`}
              key={i}
              onClick={() => handlePageClicked(i)}
            >
              <span className="name">{i}</span>
            </button>
          ))}
        </div>

        {props.totalPageCount > PAGE_LIST_PER_ONECE ? (
          <>
            <button className="btn_next cursor-pointer" onClick={() => handlePrevNextClicked(1)}>
              <span>&gt;</span>
            </button>
            <button className="btn_last cursor-pointer" onClick={() => handlePageClicked(props.totalPageCount)}>
              <span>&gt;&gt;</span>
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Pagination);
