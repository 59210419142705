import React from 'react';
import reactStringReplace from 'react-string-replace';

export enum SELECT_TYPE {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

const SelectItems = ({
  prefix,
  items,
  type,
  value,
  onChange,
}: {
  prefix: string;
  items: IV1SelectItems[];
  type: SELECT_TYPE;
  value: string;
  onChange(value: string): void;
}) => {
  const isChecked = (itemValue: string) => {
    if (type === SELECT_TYPE.RADIO) {
      return value === itemValue;
    }

    const values = value.split(',');
    return values.includes(itemValue);
  };

  const updateChange = (itemValue: string, checked: boolean) => {
    if (type === SELECT_TYPE.RADIO) {
      return onChange(itemValue);
    }

    const values = value.split(',');
    if (checked) {
      onChange((value ? [...values, itemValue] : [itemValue]).join(','));
    } else {
      onChange(values.filter((value) => value !== itemValue).join(','));
    }
  };

  return (
    <>
      {items.map((item, i) => (
        <li key={i}>
          <input
            type={type}
            id={prefix + '_' + i}
            name={prefix}
            checked={isChecked(item.value || item.text)}
            onChange={(e) => updateChange(item.value || item.text, e.target.checked)}
          />
          <label htmlFor={prefix + '_' + i}>
            <span />
            {reactStringReplace(item.text, /\[p](.*)\[\/p]/g, (match, i) => (
              <strong key={i} className="point">{match}</strong>
            ))}
          </label>
        </li>
      ))}
    </>
  );
};

export default SelectItems;
