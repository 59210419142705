import React, { useEffect } from 'react';
import '../../css/apply.css';
import GlobalStore from '../../store/global-store';
import ApplyV1 from './v1';

const Apply = () => {
  const store = GlobalStore.useContainer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [store.step]);

  return store.step === 0 ? (
    <div className="main">
      <div className="center">
        <img src="/img/apply_main_logo.png" alt="" />
        <h1>온라인 사전문진</h1>
        <div className="enter">
          <p>
            이달의 제휴안과 - <strong>GS안과의원</strong>
          </p>
          <button className="btn" onClick={() => store.setStep(1)}>
            문진 시작하기
          </button>
        </div>
      </div>
    </div>
  ) : (
    <ApplyV1 />
  );
};

export default Apply;
