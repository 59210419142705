import React from 'react';
import GlobalStore from '../../../store/global-store';
import StepButtons from './common/step-buttons';
import SelectItems, { SELECT_TYPE } from './common/select-items';

const ApplyV1Step6 = () => {
  const store = GlobalStore.useContainer();

  const handleQuestion10Change = (value: string) => {
    const values = value.split(',');
    if (values.length > 0 && values[0] !== '해당 없음' && values.filter((value: string) => value === '해당 없음').length > 0) {
      store.updateApplyData('question10', '해당 없음');
    } else {
      store.updateApplyData(
        'question10',
        values.length > 1 ? values.filter((value) => value !== '해당 없음').join(',') : value
      );
    }
  };

  return (
    <div className="wrap step6">
      <div className="logo" />
      <div className="topdown-border-box">
        <div className="question-box first">
          <div className="question-box-wrap">
            <h3>10. 다음 중 일상 생활에서 본인에게 해당하는 것이 있다면 체크해 주세요. <span className="point">(중복체크 가능)</span></h3>
            <ul className="question-list">
              <SelectItems
                prefix="question10"
                items={[
                  { text: '밤 운전을 자주하는 편이다.' },
                  { text: '컴퓨터를 장시간 사용한다.' },
                  { text: '책을 자주 읽는다.' },
                  {
                    text:
                      '시력의 세밀함이 중요한 직업(취미)를 가지고 있다.\n\t[p](예: 보석세공사, 프로게이머, 교사, 바느질 등)[/p]',
                    value: '시력의 세밀함이 중요한 직업(취미)를 가지고 있다.',
                  },
                  { text: '수술 후 근시일 내에 비행기를 타야한다.' },
                  {
                    text: '수술 후 회복시간이 충분하지 않다. [p](예: 군인, 짧은 휴가의 직장인)[/p]',
                    value: '수술 후 회복시간이 충분하지 않다.',
                  },
                  { text: '해당 없음' },
                ]}
                type={SELECT_TYPE.CHECKBOX}
                value={store.applyData?.question10 || ''}
                onChange={handleQuestion10Change}
              />
            </ul>
          </div>
        </div>
      </div>
      <StepButtons onNext={(callback) => {
        if (!store.applyData?.question10) {
          return window.alert('선택/입력 안한 문항이 있습니다.');
        }

        callback && callback();
      }} />
    </div>
  );
};

export default ApplyV1Step6;
